import _ from 'lodash';
import $ from 'jquery';
import 'normalize.css';
import 'jquery-validation';
import 'jquery-modal';

// import './assets/slick/slick.min';
// import './assets/slick/slick-theme.scss';

require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

window.jQuery = $;
window.$ = $;

require('blowup/lib/blowup');


// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper.scss';

// Styles

import './global.scss';
import './assets/styles/top-menu.scss';
import './assets/styles/side-bar.scss';
import './assets/styles/footer.scss';
import categories from "./assets/js/categories";


function checkScrollHeight(height) {
  // console.log(height);
  // console.log($(window).scrollTop());
  if($(window).scrollTop() > height) {
    $('.top-menu').addClass('fixed');
  }
  else{
    $('.top-menu').removeClass('fixed');
  }
}

$(window).scroll(function() {
  const height = $(".top-menu").outerHeight();
  checkScrollHeight(height);
});

$('.menu-toggle').click(() => {
  $('.side-bar-container').toggleClass('hide');
})

$(window).on('load', function () {

  const height = $(".top-menu").outerHeight();
  checkScrollHeight(height);

  $(".se-pre-con").fadeOut("slow");
});

$(document).ready(function() {
  var mySwiper = new Swiper('.swiper-container', {
    spaceBetween: 0,
    loop: true,
    // centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    // effect: 'fade',
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
  })
});


